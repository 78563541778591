import { get, post, put } from './client'
import { auth } from '../../firebaseConfig'

const getProfile = async () => {
	return await get(`users/${auth.currentUser?.uid || ''}`)
}

// DEPRECTED
const getProfileByRFID = async ({ queryKey }) => {
	return await get(`users/${queryKey[1]}`)
}

const createProfile = async (body) => {
	return await post('users', {
		body
	})
}

const updateProfile = async (body) => {
	return await put('users', {
		body
	})
}

const updateBonusCreditsNotificationGroupId = async (body) => {
	return await put(`users/${body.id}/show-bonus-credit-notification`, {
		body
	})
}

const validateNickname = async ({ queryKey }) => {
	return await get(`users/validate-nickname/${queryKey[1]}`)
}

export {
	getProfile,
	getProfileByRFID,
	createProfile,
	updateProfile,
	validateNickname,
	updateBonusCreditsNotificationGroupId
}
