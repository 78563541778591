import React, { createContext, useContext, useState, useEffect } from 'react'
import { auth } from '../../firebaseConfig'
import { onAuthStateChanged } from 'firebase/auth'
import { getProfile } from '../services/user'
import useGlobalState from '../helpers/useGlobalState'
import { handleSignOut } from '../services/auth'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@chakra-ui/react'
import { hasMissingFields } from '../helpers'
import Splash from '../splash'
import { navigate } from 'gatsby'
import { checkAccess } from '../helpers/routes'
import {
	removeEventId,
	removeTempGroup,
	removeTempTeam,
	setEventId,
	setRedirectUrl,
	setRFID,
	setTempGroup,
	setTempTeam,
	setDefaultGroup
} from '../helpers/database'
import { updateProfile } from '../services/user'
// import { getTeamByRfid } from '../services/team'
import { getGroupByRfid } from '../services/group'

const AuthContext = createContext()
let PAGE_NAME = ''

const AuthProvider = ({ children }) => {
	const [user, setUser] = useGlobalState(null)
	const [profile, setProfile] = useState({})
	// RFID Connected Team
	const [connectedTeam, setConnectedTeam] = useState({})
	const [appLoaded, setAppLoaded] = useState(false)
	const [isProfileLoaded, setIsProfileLoaded] = useState(false)
	const [loadingAuth, setLoadingAuth] = useState(true)
	const queryClient = useQueryClient()
	const toast = useToast()
	const missingRequired = hasMissingFields(profile)
	const page = children.props.path
	const isLoggedIn = !!user

	if (children.props.location.search && !!window) {
		const searchParams = new URLSearchParams(children.props.location.search)
		const search = {}
		for (const [key, value] of searchParams) {
			search[key] = value
		}

		window.history.pushState(search, '')
	}

	const mutationUser = useMutation(updateProfile, {
		onSuccess: async (data) => {
			// await refreshProfile()
			return data
		},
		onError: (error) => {
			console.log(error)
		}
	})

	const getProfileQuery = async () => {
		try {
			const spUser = await queryClient.fetchQuery(
				['profile'],
				getProfile,
				{
					staleTime: 0
				}
			)
			if (user && user?.email !== spUser.email) {
				mutationUser.mutate({ email: user.email })
			}
			if (spUser?.rfid) {
				// fetch linked RFID for most recent team
				try {
					const groupQuery = await queryClient.fetchQuery({
						queryKey: ['rfid', spUser?.rfid],
						queryFn: getGroupByRfid
					})
					// set in the localStorage for Leaderboard currently connected
					// sets the default group on the Leaderboard menu
					setDefaultGroup(groupQuery?.id)
					// setConnectedTeam(teamQuery)
				} catch (error) {
					console.error('Unable to set default team', error)
				}
			}
			return spUser
		} catch (error) {
			console.error(error)
			toast({
				description: error.message,
				status: 'error',
				isClosable: true,
				position: 'top',
				duration: 4000,
				containerStyle: { marginLeft: { lg: 400, base: 0 } }
			})
		}
	}

	const logout = () => {
		setProfile({})
		setUser(null)
		return handleSignOut()
	}

	const fetchProfile = async () => {
		// fetch fairgame profile
		const profileData = await getProfileQuery()

		setProfile(profileData)
		setIsProfileLoaded(true)
		setLoadingAuth(false)
	}

	const refreshProfile = async () => {
		await queryClient.invalidateQueries(['profile'], {
			exact: true
		})
		await fetchProfile()
	}

	useEffect(() => {
		// firebase auth status
		let unsubscribe
		unsubscribe = onAuthStateChanged(auth, async (userResult) => {
			if (!userResult) {
				return setLoadingAuth(false)
			}
			mutationUser.mutate({ email: userResult.email })
			await fetchProfile(userResult)
			setUser(userResult)
		})
		return () => {
			if (unsubscribe) {
				unsubscribe()
			}
		}
	}, [])

	useEffect(() => {
		setTimeout(() => {
			setAppLoaded(true)
		}, 1000)
	}, [])

	if (!appLoaded || loadingAuth) {
		return <Splash />
	}

	// Only check for access for the first time the page is loaded
	if (PAGE_NAME !== page) {
		PAGE_NAME = page
		const redirectPage = checkAccess(isLoggedIn, missingRequired)

		if (!!redirectPage) {
			if (window.location.search.indexOf('autojoin') > -1) {
				setRedirectUrl(
					window.location.pathname + window.location.search
				)
			}
			const currState = window.history.state

			navigate(redirectPage, {
				state: {
					redirect: PAGE_NAME.replace(/\//g, ''),
					...currState
				}
			})

			return <Splash />
		}
	}

	const resetInVenueOnboarding = async (resetEventId = true) => {
		if (resetEventId) {
			removeEventId()
		}

		navigate('/in-venue-onboarding')

		logout()
		removeTempTeam()
		removeTempGroup()
		setRFID(null)
	}

	return (
		<AuthContext.Provider
			value={{
				user,
				connectedTeam,
				isLoggedIn,
				isProfileLoaded,
				loadingAuth,
				profile,
				missingRequired,
				fetchProfile,
				refreshProfile,
				logout,
				resetInVenueOnboarding
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => useContext(AuthContext)

export default AuthProvider
