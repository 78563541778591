const Colors = {
	light: '#EFC400',
	dark: '#000000',
	gold: '#EFC400',
	yellow: {
		200: '#EFC400',
		500: '#EFC400'
	},
	pink: '#FF94B2',
	lightPink: '#FFB3C8',
	silver: '#C8C8C8',
	darkGray: '#333333',
	gray: '#939393',
	secondaryGrey: '#939393',
	offBlack: '#060606'
}

export default Colors
