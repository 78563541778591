exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-event-code-js": () => import("./../../../src/pages/event-code.js" /* webpackChunkName: "component---src-pages-event-code-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-groups-[id]-choose-avatar-js": () => import("./../../../src/pages/groups/[id]/choose-avatar.js" /* webpackChunkName: "component---src-pages-groups-[id]-choose-avatar-js" */),
  "component---src-pages-groups-[id]-create-team-js": () => import("./../../../src/pages/groups/[id]/create-team.js" /* webpackChunkName: "component---src-pages-groups-[id]-create-team-js" */),
  "component---src-pages-groups-[id]-group-created-js": () => import("./../../../src/pages/groups/[id]/group-created.js" /* webpackChunkName: "component---src-pages-groups-[id]-group-created-js" */),
  "component---src-pages-groups-[id]-index-js": () => import("./../../../src/pages/groups/[id]/index.js" /* webpackChunkName: "component---src-pages-groups-[id]-index-js" */),
  "component---src-pages-groups-[id]-team-index-js": () => import("./../../../src/pages/groups/[id]/team/index.js" /* webpackChunkName: "component---src-pages-groups-[id]-team-index-js" */),
  "component---src-pages-groups-[id]-team-team-id-booking-linked-js": () => import("./../../../src/pages/groups/[id]/team/[teamId]/booking-linked.js" /* webpackChunkName: "component---src-pages-groups-[id]-team-team-id-booking-linked-js" */),
  "component---src-pages-groups-[id]-team-team-id-choose-avatar-js": () => import("./../../../src/pages/groups/[id]/team/[teamId]/choose-avatar.js" /* webpackChunkName: "component---src-pages-groups-[id]-team-team-id-choose-avatar-js" */),
  "component---src-pages-groups-[id]-team-team-id-index-js": () => import("./../../../src/pages/groups/[id]/team/[teamId]/index.js" /* webpackChunkName: "component---src-pages-groups-[id]-team-team-id-index-js" */),
  "component---src-pages-groups-[id]-team-team-id-team-created-js": () => import("./../../../src/pages/groups/[id]/team/[teamId]/team-created.js" /* webpackChunkName: "component---src-pages-groups-[id]-team-team-id-team-created-js" */),
  "component---src-pages-groups-[id]-team-team-id-update-team-js": () => import("./../../../src/pages/groups/[id]/team/[teamId]/update-team.js" /* webpackChunkName: "component---src-pages-groups-[id]-team-team-id-update-team-js" */),
  "component---src-pages-groups-index-js": () => import("./../../../src/pages/groups/index.js" /* webpackChunkName: "component---src-pages-groups-index-js" */),
  "component---src-pages-in-venue-onboarding-event-code-js": () => import("./../../../src/pages/in-venue-onboarding/event-code.js" /* webpackChunkName: "component---src-pages-in-venue-onboarding-event-code-js" */),
  "component---src-pages-in-venue-onboarding-index-js": () => import("./../../../src/pages/in-venue-onboarding/index.js" /* webpackChunkName: "component---src-pages-in-venue-onboarding-index-js" */),
  "component---src-pages-in-venue-onboarding-link-rfid-js": () => import("./../../../src/pages/in-venue-onboarding/link-rfid.js" /* webpackChunkName: "component---src-pages-in-venue-onboarding-link-rfid-js" */),
  "component---src-pages-in-venue-onboarding-name-js": () => import("./../../../src/pages/in-venue-onboarding/name.js" /* webpackChunkName: "component---src-pages-in-venue-onboarding-name-js" */),
  "component---src-pages-in-venue-onboarding-ready-to-play-js": () => import("./../../../src/pages/in-venue-onboarding/ready-to-play.js" /* webpackChunkName: "component---src-pages-in-venue-onboarding-ready-to-play-js" */),
  "component---src-pages-in-venue-onboarding-terms-js": () => import("./../../../src/pages/in-venue-onboarding/terms.js" /* webpackChunkName: "component---src-pages-in-venue-onboarding-terms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-booking-event-id-index-js": () => import("./../../../src/pages/join-booking/[eventId]/index.js" /* webpackChunkName: "component---src-pages-join-booking-event-id-index-js" */),
  "component---src-pages-leaderboards-[id]-index-js": () => import("./../../../src/pages/leaderboards/[id]/index.js" /* webpackChunkName: "component---src-pages-leaderboards-[id]-index-js" */),
  "component---src-pages-leaderboards-index-js": () => import("./../../../src/pages/leaderboards/index.js" /* webpackChunkName: "component---src-pages-leaderboards-index-js" */),
  "component---src-pages-login-account-created-js": () => import("./../../../src/pages/login/account-created.js" /* webpackChunkName: "component---src-pages-login-account-created-js" */),
  "component---src-pages-login-admin-login-js": () => import("./../../../src/pages/login/adminLogin.js" /* webpackChunkName: "component---src-pages-login-admin-login-js" */),
  "component---src-pages-login-birthdate-js": () => import("./../../../src/pages/login/birthdate.js" /* webpackChunkName: "component---src-pages-login-birthdate-js" */),
  "component---src-pages-login-finish-registration-js": () => import("./../../../src/pages/login/finish-registration.js" /* webpackChunkName: "component---src-pages-login-finish-registration-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-login-name-js": () => import("./../../../src/pages/login/name.js" /* webpackChunkName: "component---src-pages-login-name-js" */),
  "component---src-pages-login-not-valid-js": () => import("./../../../src/pages/login/not-valid.js" /* webpackChunkName: "component---src-pages-login-not-valid-js" */),
  "component---src-pages-login-terms-js": () => import("./../../../src/pages/login/terms.js" /* webpackChunkName: "component---src-pages-login-terms-js" */),
  "component---src-pages-login-verify-email-js": () => import("./../../../src/pages/login/verify-email.js" /* webpackChunkName: "component---src-pages-login-verify-email-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-public-leaderboard-js": () => import("./../../../src/pages/public-leaderboard.js" /* webpackChunkName: "component---src-pages-public-leaderboard-js" */),
  "component---src-pages-rfid-confirm-js": () => import("./../../../src/pages/rfid/confirm.js" /* webpackChunkName: "component---src-pages-rfid-confirm-js" */),
  "component---src-pages-rfid-group-js": () => import("./../../../src/pages/rfid/group.js" /* webpackChunkName: "component---src-pages-rfid-group-js" */),
  "component---src-pages-rfid-index-js": () => import("./../../../src/pages/rfid/index.js" /* webpackChunkName: "component---src-pages-rfid-index-js" */),
  "component---src-pages-start-js": () => import("./../../../src/pages/start.js" /* webpackChunkName: "component---src-pages-start-js" */),
  "component---src-pages-test-scores-js": () => import("./../../../src/pages/test-scores.js" /* webpackChunkName: "component---src-pages-test-scores-js" */),
  "component---src-pages-venue-tv-js": () => import("./../../../src/pages/venue-tv.js" /* webpackChunkName: "component---src-pages-venue-tv-js" */),
  "component---src-templates-game-js": () => import("./../../../src/templates/game.js" /* webpackChunkName: "component---src-templates-game-js" */)
}

