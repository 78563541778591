const getData = (type) => {
	return typeof window !== 'undefined'
		? window.localStorage.getItem(type)
		: null
}

const storeData = (type, value) => {
	if (typeof window !== 'undefined') {
		window.localStorage.setItem(type, value)
	}
}

const storeListData = (type, value) => {
	if (typeof window !== 'undefined') {
		window.localStorage.setItem(type, JSON.stringify(value))
	}
}

const removeData = (type) => {
	if (typeof window !== 'undefined') {
		window.localStorage.removeItem(type)
	}
}

const getTempProfile = () => {
	const data = getData('temp_profile')
	return data && data !== 'undefined' && typeof data !== 'undefined'
		? JSON.parse(data)
		: {}
}

const setTempProfile = (value) => {
	storeListData('temp_profile', value)
}

const getTempGroup = (value) => {
	const data = getData('temp_group')
	return data && data !== 'undefined' && typeof data !== 'undefined'
		? JSON.parse(data)
		: {}
}

const setTempGroup = (value) => {
	storeListData('temp_group', value)
}

const removeTempGroup = () => {
	removeData('temp_group')
}
const removeTempProfile = () => {
	removeData('temp_profile')
}

const getUserData = () => {
	const data = getData('user_date')
	return data && data !== 'undefined' && typeof data !== 'undefined'
		? JSON.parse(data)
		: null
}

const setUserData = (value) => {
	storeListData('user_date', value)
}

const removeUserData = () => {
	removeData('user_date')
}

const getTempTeam = () => {
	const data = getData('temp_team')
	return data && typeof data !== 'undefined' ? JSON.parse(data) : {}
}

const setTempTeam = (value) => {
	storeListData('temp_team', value)
}

const removeTempTeam = () => {
	removeData('temp_team')
}

const getBookingId = () => {
	return getData('bookingId')
}

const setBookingId = (value) => {
	storeData('bookingId', value)
}

const removeBookingId = () => {
	removeData('bookingId')
}

const getEventId = () => {
	return getData('eventId')
}

const setEventId = (value) => {
	storeData('eventId', value)
}

const removeEventId = () => {
	removeData('eventId')
}

const getEmail = () => {
	return getData('email')
}

const setEmail = (value) => {
	storeData('email', value)
}

const removeEmail = () => {
	removeData('email')
}

const getRFID = () => {
	const data = getData('rfid')
	return data && typeof data !== 'undefined' ? JSON.parse(data) : {}
}

const setRFID = (value) => {
	storeListData('rfid', value)
}

const removeRFID = () => {
	removeData('rfid')
}

const getRedirectUrl = () => {
	return getData('redirect')
}

const setRedirectUrl = (value) => {
	storeData('redirect', value)
}

const removeRedirectUrl = () => {
	removeData('redirect')
}

const getErrorMessage = () => {
	const data = getData('error')
	return data && typeof data !== 'undefined' ? JSON.parse(data) : {}
}

const setErrorMessage = (type, message) => {
	storeListData('error', {
		type,
		message
	})
}

const removeErrorMessage = () => {
	removeData('error')
}

const setDefaultGroup = (value) => {
	storeListData('defaultGroup', value)
}

const removeDefaultGroup = () => {
	removeData('defaultGroup')
}

const getDefaultGroup = () => {
	return getData('defaultGroup')
}

const getIsAdmin = () => {
	return getData('isAdmin')
}

const setIsAdmin = (value) => {
	storeData('isAdmin', value)
}

export {
	getTempProfile,
	setTempProfile,
	removeTempProfile,
	getUserData,
	setUserData,
	removeUserData,
	getBookingId,
	setBookingId,
	removeBookingId,
	getTempTeam,
	setTempTeam,
	removeTempTeam,
	getRFID,
	setRFID,
	removeRFID,
	getEmail,
	setEmail,
	removeEmail,
	getRedirectUrl,
	setRedirectUrl,
	removeRedirectUrl,
	getErrorMessage,
	setErrorMessage,
	removeErrorMessage,
	getEventId,
	removeEventId,
	setEventId,
	removeDefaultGroup,
	setDefaultGroup,
	getDefaultGroup,
	getTempGroup,
	setTempGroup,
	removeTempGroup,
	getIsAdmin,
	setIsAdmin
}
