import { useEffect, useState } from 'react'

export const GlobalStates = {
	PRIMARY: 'primary',
	AUTH: 'auth',
}
// To support different states instead of one global state that all components listen to
let states = {}

const setInitialState = (type, initialState, listener) => {
	if (!initialState || !states[type] || states[type].state !== null) return
	states[type].state = initialState
	listener(states[type].state)
}

const setState = (type, newState) => {
	if (!states[type]) return
	states[type].state = newState
	states[type].listeners.forEach((listener) => {
		listener(states[type].state)
	})
}

const useGlobalState = (type = GlobalStates.PRIMARY, initialState = null) => {
	if (!states[type]) {
		states[type] = { state: null, listeners: [] }
	}
	const newListener = useState()[1]
	useEffect(() => {
		// Set initial state if exists
		setInitialState(type, initialState, newListener)
		// Add a new listener when the component mount
		states[type].listeners.push(newListener)
		return () => {
			// Remove listeners when the component unmount
			states[type].listeners = states[type].listeners.filter(
				(listener) => listener !== newListener
			)
		}
	}, [])
	const setTypeState = (newState) => {
		setState(type, newState)
	}
	return [states[type].state, setTypeState]
}

export default useGlobalState
